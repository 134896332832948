






































































































































































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import ModalPGTToBP from '@/components/PGT/ModalPGTToBP.vue'
import windowWidth from '@/mixins/windowWidth'
import Pagination from '@/components/Pagination/index.vue'
import Search from '@/components/Search/Search.vue'
import { TheMask, mask } from 'vue-the-mask'
import { DATE_SORTING } from '@/common/constants'

export default Vue.extend({
  name: 'pgt-profiles',

  components: {
    Loading,
    ModalPGTToBP,
    TheMask,
    Search,
    Pagination
  },

  directives: {
    mask
  },

  mixins: [
    windowWidth
  ],

  data () {
    return {
      searchPlaceholder: 'Введите логин или телефон',
      // size: 8,
      // page: 1,
      limit: 25,
      offset: 0,
      loading: false,
      searchValue: '',
      sortByDate: '',
      isShownModal: false,
      fields: [
        {
          key: 'created_at',
          label: 'Дата создания',
          sortable: true
        },
        {
          key: 'id',
          label: 'ID учетной записи'
        },
        {
          key: 'username',
          label: 'Логин'
        },
        {
          key: 'phone',
          label: 'Номер телефона'
        },
        {
          key: 'business_profile.location.address',
          label: 'Локация бизнеса'
        },
        {
          key: 'button',
          label: ''
        }
      ],
      changedId: '',
      changedUsername: ''
    }
  },

  computed: {
    ...mapState('pgt', [
      'pgtProfiles',
      'pgtProfilesCount'
    ])
  },

  watch: {
    $route () {
      this.offset = Number(this.$route.query.offset || 0)
      // this.page = Number(this.$route.query.page || 1)
      this.searchValue = this.$route.query.search || ''
      this.sortByDate = this.$route.query.orderBy || ''
      this.getPgtProfilesPage()
    },

    searchValue (value) {
      if (!value.length && this.$route.query?.search?.length) {
        this.$router.push(this.$route.path)
      }
    }
  },

  async mounted () {
    // this.page = Number(this.$route.query.page || 1)
    this.searchValue = this.$route.query.search || ''
    this.sortByDate = this.$route.query.orderBy || ''
    this.offset = Number(this.$route.query.offset || 0)
    await this.getPgtProfilesPage()
  },

  methods: {
    ...mapActions('pgt', [
      'getPgtProfiles'
    ]),

    sortingChanged (ctx) {
      if (!this.sortByDate.length || DATE_SORTING.descending === this.sortByDate) {
        this.sortByDate = DATE_SORTING.ascending
      } else {
        this.sortByDate = DATE_SORTING.descending
      }
      this.setRouteQuery('orderBy', this.sortByDate)
      // this.setRouteQuery('page', 1)
    },

    setRouteQuery (paramName: string, paramValue) {
      const query = { ...this.$route.query, [paramName]: paramValue }

      this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    },

    async handleSearch () {
      if (!this.searchValue.trim()) {
        this.$refs['search-input'].$el.focus()
        return
      }

      this.page = 1
      this.setRouteQuery('search', this.searchValue.trim())
    },

    async clearSearchValue () {
      this.searchValue = ''
    },

    changeTab (tabNumber) {
      this.page = tabNumber
      this.setRouteQuery('page', this.page)
    },

    async getPgtProfilesPage () {
      try {
        this.loading = true
        await this.getPgtProfiles({
          filter_value: this.searchValue,
          // page: this.page,
          // size: this.size,
          order_by: this.sortByDate,
          offset: this.offset,
          limit: this.limit
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    showDetails (pgtProfile: any) {
      // if (request.profile_type === 'admin') return
      this.$router.push({ name: 'pgt-detail', params: { profileId: pgtProfile.id } })
    },

    openModal (pgtProfile: any) {
      this.changedUsername = pgtProfile.username
      this.changedId = pgtProfile.id
      this.isShownModal = true
    },

    // TODO: remove it when willbe pagination on the API side
    goPrevPage () {
      if (this.offset <= 0) {
        this.offset = 0
        return
      }
      this.offset -= this.limit
    },

    goNextPage () {
      this.offset += this.limit
    }
  }
})
